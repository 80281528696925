import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import RecentPosts from "./RecentPosts";
import { addHeaderTagOnce, removeHeaderTag } from "../global/Global";

const Tag = () => {
  let { tag } = useParams();

  useEffect(() => {
    document.title = `#${tag} - Talkvio`;
    addHeaderTagOnce("robots", "noindex, follow", "name");
    return () => {
      removeHeaderTag("robots", "name");
    };
  }, []);

  return (
    <div>
      <div className="recentPosts">
        <RecentPosts
          source="tag"
          tag={tag}
          noLimitation={true}
          leftRatingDisplay={true}
          periodSwitcher={true}
          recentPopularSwitcher={true}
        />
      </div>
    </div>
  );
};
export default Tag;
